import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Establecimiento } from '../models/establecimiento';
import { ArticuloResult } from '../models/articulo-result';
import { Grupo } from '../models/grupo';
import { Zona } from '../models/zona';
import { Mesa } from '../models/mesa';
import { Pedido } from '../models/pedido';
import { MesaResult } from '../models/mesa-result';
import { EstablecimientoResult } from '../models/establecimiento-result';
import { PedidoResult } from '../models/pedido-result';
import { DatosVariosResult } from '../models/datos-varios-result';

@Injectable({
  providedIn: 'root'
})
export class CartaOnlineService {

  constructor(private http: HttpClient,private storageService: StorageService ) { }

  public url: string = environment.baseUrl+'/api/';
  private IdCamarero:string=environment.intCamareroId;

  public headers = new HttpHeaders().set('Content-Type','application/json');
  //private cookie=this.storageService.getCurrentSession().strCookie;

//cookie="52ef0e28-4ddf-4beb-b1fa-1a2c1bc15066-1-20181010123454411";
  getEstablecimiento(cookie):Observable<EstablecimientoResult>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'establecimiento/general/detalle?'+dato;
 
    return this.http.get<EstablecimientoResult>(urlCompleta,  {headers: this.headers});
  }

  getConfiguracion(cookie):Observable<Establecimiento[]>{ 
    let dato='strCookie='+cookie+'&datoAdicional=0';
    let urlCompleta=this.url+'establecimiento?'+dato;
 
    return this.http.get<Establecimiento[]>(urlCompleta,  {headers: this.headers});
  }

  getArticulos(cookie,mesa,articuloId,destacado,grupoId):Observable<ArticuloResult>{ 
    let dato='strCookie='+cookie+'&intMesaId='+mesa+'&intArticuloId='+articuloId+'&strDestacado='+destacado+'&intGrupoId='+grupoId;
    let urlCompleta=this.url+'articulo?'+dato;
 
    return this.http.get<ArticuloResult>(urlCompleta,  {headers: this.headers});
  }

  getArticulos2(cookie,mesa,articuloId,destacado,grupoId) { 
    let dato='strCookie='+cookie+'&intMesaId='+mesa+'&intArticuloId='+articuloId+'&strDestacado='+destacado+'&intGrupoId='+grupoId;
    let urlCompleta=this.url+'articulo?'+dato;
 
    return this.http.get(urlCompleta,  {headers: this.headers}).toPromise();
  }

  getGrupos(cookie):Observable<Grupo[]>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'grupo?'+dato;
 
    return this.http.get<Grupo[]>(urlCompleta,  {headers: this.headers});
  }

  getZona(cookie,mesa):Observable<Zona[]>{ 
    let dato='strCookie='+cookie+'&intMesaId='+mesa;
    let urlCompleta=this.url+'zona?'+dato;
 
    return this.http.get<Zona[]>(urlCompleta,  {headers: this.headers});
  }

  getMesas(cookie,mesa):Observable<MesaResult>{
    let dato='strCookie='+cookie+'&intMesaId='+mesa;
    let urlCompleta=this.url+'Mesa/detalle?'+dato;
 
    return this.http.get<MesaResult>(urlCompleta,{headers: this.headers});
  }

  getPoliticaCookies(cookie):Observable<any>{ 
    let dato='strMiCookie='+cookie;
    let urlCompleta=this.url+'establecimiento?'+dato;
 
    return this.http.get<any>(urlCompleta,  {headers: this.headers});
  }

  /**************** UNICO REST DEVUELVE DATOS CARTA ONLINE ***************/
  
  getDatosVarios(cookie,mesa):Observable<DatosVariosResult>{
    let dato='strCookie='+cookie+'&intMesaId='+mesa;
    let urlCompleta=this.url+'PedidoCartaOnline/datosvarios?'+dato;
 
    return this.http.get<DatosVariosResult>(urlCompleta,{headers: this.headers});
  }

  /**************** UNICO REST DEVUELVE PEDIDO Y LINEAS CARTA ONLINE ***************/

  getPedidoCompleto(cookie,cerrado,pedido,strMesaId):Observable<PedidoResult>{
    let dato='strCookie='+cookie+'&strCamareroId='+this.IdCamarero+'&strCerrado='+cerrado+'&strPedidoId='+pedido+'&strMesaId='+strMesaId;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'PedidoCartaOnline/pedidocompleto?'+dato;
 
    return this.http.get<PedidoResult>(urlCompleta,{headers: this.headers});
  }

  /**************** UNICO REST CAMBIA CANTIDAD LINEA DEVUELVE PEDIDO Y LINEAS CARTA ONLINE ***************/

  putLineaPedido(cookie,pedidoLinea,strMesaId):Observable<PedidoResult>{
    let urlCompleta=this.url+'PedidoCartaOnline/editalineapedido?strCookie='+cookie+'&strMesaId='+strMesaId;
    var jsonString = JSON.stringify(pedidoLinea);
    return this.http.put<PedidoResult>(urlCompleta, jsonString, {headers: this.headers});
  }

  /************** REALIZAR PEDIDOS DESDE CARTA ONLINE **************/

  verificaPassPedido(cookie, strMesaId, strClave):Observable<PedidoResult>{
    let urlCompleta=this.url+'PedidoCartaOnline/updatepass?strCookie='+cookie+'&strMesaId='+strMesaId+'&strClave='+strClave;
    return this.http.get<PedidoResult>(urlCompleta, {headers: this.headers});
  }

  putPedido(cookie, strPedidoId, strMesaId,articulo,strClave,cantidad):Observable<PedidoResult>{
    let urlCompleta=this.url+'PedidoCartaOnline?strCookie='+cookie+'&strPedidoId='+strPedidoId+'&strMesaId='+strMesaId+'&strClave='+strClave+'&intCantidad='+cantidad;
    var jsonString = JSON.stringify(articulo);
    return this.http.put<PedidoResult>(urlCompleta, jsonString, {headers: this.headers});
  }

  getCabeceraPedido(cookie,cerrado,pedido,strMesaId):Observable<Pedido[]>{
    let dato='strCookie='+cookie+'&strCamareroId='+this.IdCamarero+'&strCerrado='+cerrado+'&strPedidoId='+pedido+'&strMesaId='+strMesaId;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'PedidoCartaOnline?'+dato;
 
    return this.http.get<Pedido[]>(urlCompleta,{headers: this.headers});
  }

  getLineasPedido(cookie,pedido,strMesaId):Observable<any>{
    let dato='strCookie='+cookie+'&strPedidoId='+pedido+'&strMesaId='+strMesaId;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'PedidoLinea?'+dato;
 
    return this.http.get<any>(urlCompleta,{headers: this.headers});
  }

  putSumaLineaPedido(cookie,pedidoLinea,strMesaId):Observable<PedidoResult>{
    let urlCompleta=this.url+'PedidoLinea/sumararticulo?strCookie='+cookie+'&strMesaId='+strMesaId;
    var jsonString = JSON.stringify(pedidoLinea);
    return this.http.put<PedidoResult>(urlCompleta, jsonString, {headers: this.headers});
  }

  putRestaLineaPedido(cookie,pedidoLinea,strMesaId):Observable<PedidoResult>{
    let urlCompleta=this.url+'PedidoLinea/restararticulo?strCookie='+cookie+'&strMesaId='+strMesaId;
    var jsonString = JSON.stringify(pedidoLinea);
    return this.http.put<PedidoResult>(urlCompleta, jsonString, {headers: this.headers});
  }

  deleteLineasPedido(cookie,lineas,strMesaId):Observable<any>{
    let urlCompleta=this.url+'PedidoLinea?strCookie='+cookie+'&strMesaId='+strMesaId;
    var jsonString = JSON.stringify(lineas);
    return this.http.put<any>(urlCompleta, jsonString, {headers: this.headers});
  }

  finalizaPedido(cookie,pedido,strMesaId):Observable<any>{
    let urlCompleta=this.url+'PedidoCartaOnline/finalizar?strCookie='+cookie+'&strMesaId='+strMesaId;
    var jsonString = JSON.stringify(pedido);
    return this.http.post<any>(urlCompleta, jsonString, {headers: this.headers});
  }
}
