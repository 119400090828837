import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { NombreClienteComponent } from '../nombre-cliente/nombre-cliente.component';
import { Articulo } from 'src/app/models/articulo';
import { Observable } from 'rxjs';
import { Zona } from 'src/app/models/zona';
import { Establecimiento } from 'src/app/models/establecimiento';
import { Grupo } from 'src/app/models/grupo';
import { PedidoLinea } from 'src/app/models/pedido-linea';
import { Pedido } from 'src/app/models/pedido';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { DataCookie } from 'src/app/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';
import { DataCookiePedido } from 'src/app/models/data-cookie-pedido';
import { VentanaNumLineasComponent } from '../ventana-num-lineas/ventana-num-lineas.component';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent implements OnInit {

  abierto:boolean=true;
  cerrado:boolean=false;

  grupoId:any;

  listArticulos:Articulo[]=[];
  listArticulos2: Observable<Articulo[]>;
  listaTemporal:any;
  miEstablecimiento:Establecimiento=new Establecimiento();
  listZona:Zona[]=[];

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  miGrupo:Grupo=new Grupo();

  myLoader:boolean=false;

  muestraTotalArticulos:boolean=false;
  totalArticulos:number;

  // pedidoLineas:boolean=false;
  listPedidoLineas:PedidoLinea[]=[];
  // listPedidos:Pedido[]=[];
  miPedido:Pedido=new Pedido();
  listLineasEliminar:PedidoLinea[]=[];

  // miCooKie:string=environment.strCookie;
  // MesaId:string=environment.intIdMesa;
  intIdPedido:string=environment.intIdPedido;

  // strColorMenu:string=environment.strColorMenu;
  // strColorTextoMenu:string=environment.strColorTextoMenu;
  // strColorCuerpo:string=environment.strColorCuerpo;
  // strColorTextoCuerpo:string=environment.strColorTextoCuerpo;
  // strNombreZona:string=environment.strNombreZona;
  // strNombreMesa:string=environment.strNombreMesa;

  strMesa:string;
  strZona:string;
  idEstablecimiento:string="";
  midataCookie:DataCookie=new DataCookie();
  cookieNombre:string=environment.strCookie;

  cookiePedido=environment.strCookiePedido;
  midataCookiePedido:DataCookiePedido=new DataCookiePedido();

  constructor(private miservicio:CartaOnlineService,private router: Router,private route: ActivatedRoute,public dialog: MatDialog, private cookieService: CookieService,
    private renderer: Renderer2) {
    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
    });
  }

  ngOnInit() {
    this.myLoader=true;
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.idEstablecimiento);
    if(IsCookieExists){
      this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
      // console.log("1" + new Date().toLocaleString())

      let IsCookiePedidoExists:boolean=this.cookieService.check(this.cookiePedido+this.idEstablecimiento);
      if(IsCookiePedidoExists){
        this.midataCookiePedido=JSON.parse(this.cookieService.get(this.cookiePedido+this.idEstablecimiento));
        // console.log("2" + new Date().toLocaleString())
        setTimeout(()=>{
          this.colorCuerpo=this.midataCookie.strColorCuerpo;
          this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
          this.colorMenu=this.midataCookie.strColorMenu;
          this.colorTextoMenu=this.midataCookie.strColorTextoMenu;
          this.strMesa=this.midataCookie.nombreMesa;
          this.strZona=this.midataCookie.nombreZona;
          // console.log("3" + new Date().toLocaleString())
          this.datosEstablecimiento();
          // this.recuperoPedidoCabecera();
          this.recuperaPedidoCompleto();
        }, 500)
      }else{
        let extra: NavigationExtras = {
          queryParams: {
            'id':this.idEstablecimiento
          }
        }
        this.router.navigate(['/CartaOnline'], extra)
      }
    }else{
      // console.log('No hay cookie')
      this.mensajeError("La cookie ha caducado, por favor escanee nuevamente el codigo QR");
    }
    
    
  }

  // cargarVariablesCookies(){
  //   this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
  //   this.colorMenu=sessionStorage.getItem(this.strColorMenu);
  //   this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);
  //   this.colorTextoMenu=sessionStorage.getItem(this.strColorTextoMenu);
  // }

  recuperoPedidoCabecera(){
    // console.log(this.midataCookiePedido)
    this.miservicio.getCabeceraPedido(this.midataCookie.coo,'N',this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
      // console.log(datos);
      if(datos.length>0){
        // console.log("pedido" + new Date().toLocaleString())
        this.miPedido=datos[0];
        this.recuperoTotalArticulos();
      }else{
        this.myLoader=false;
        this.mensajeError("La mesa esta bloqueada, por favor avise al camarero");

        let extra: NavigationExtras = {
          queryParams: {
            'id':this.idEstablecimiento
          }
        }
        this.router.navigate(['/CartaOnline'], extra)
        // console.log(11)

        // let dialogRef = this.dialog.open(MensajeErrorComponent, {
        //   width: '600px',
        //   data: {
        //     'strMensaje':"La mesa esta bloqueada, por favor avise al camarero"
        //   }
        // });
        // dialogRef.afterClosed().subscribe(() => {
        //   let extra: NavigationExtras = {
        //     queryParams: {
        //       'id':this.idEstablecimiento
        //     }
        //   }
        //   this.router.navigate(['/CartaOnline'], extra)
        // });

      }
    });
  }

  recuperaPedidoCompleto(){
    // console.log("todo" + new Date().toLocaleString())
    this.miservicio.getPedidoCompleto(this.midataCookie.coo,'N',this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        // console.log("todo" + new Date().toLocaleString())
        this.miPedido=datos.miListPedidos[0];
        if(datos.miListPedidoLinea.length>0){
          this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
        }
        // console.log(datos[datos.length-1].decTotalArticulos)
        this.muestraTotalArticulos=true;
        this.listPedidoLineas=datos.miListPedidoLinea;
        this.myLoader=false;
      }else{
        this.myLoader=false;
        this.mensajeError(datos.miRespuesta.strMensaje);
        // console.log(33)
        let extra: NavigationExtras = {
          queryParams: {
            'id':this.idEstablecimiento
          }
        }
        this.router.navigate(['/CartaOnline'], extra)


        // let dialogRef = this.dialog.open(MensajeErrorComponent, {
        //   width: '600px',
        //   data: {
        //     'strMensaje':"La mesa esta bloqueada, por favor avise al camarero"
        //   }
        // });
        // dialogRef.afterClosed().subscribe(() => {
        //   let extra: NavigationExtras = {
        //     queryParams: {
        //       'id':this.idEstablecimiento
        //     }
        //   }
        //   this.router.navigate(['/CartaOnline'], extra)
        // });

      }
    })
  }

  // recuperaLineasPedido(){
  //   this.miservicio.getLineasPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
  //     // console.log(datos);
  //     // this.listPedidoLineas=datos;
  //     // this.myLoader=false;     
  //   })
  // }

  recuperoTotalArticulos(){
    let IsCookiePedidoExists:boolean=this.cookieService.check(this.cookiePedido+this.idEstablecimiento);
    if(IsCookiePedidoExists){
    // if(this.midataCookiePedido.intPedidoId!=null){
    
      this.miservicio.getLineasPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
        // console.log(datos)
        if(datos.length>0){
          console.log("lineas" + new Date().toLocaleString())
          this.totalArticulos=datos[datos.length-1].decTotalArticulos;
          // console.log(datos[datos.length-1].decTotalArticulos)
          this.muestraTotalArticulos=true;
          this.listPedidoLineas=datos;
        }else{
          this.mensajeError("La mesa esta bloqueada, por favor avise al camarero");

          let extra: NavigationExtras = {
            queryParams: {
              'id':this.idEstablecimiento
            }
          }
          this.router.navigate(['/CartaOnline'], extra)
          // console.log(22)

          // let dialogRef = this.dialog.open(MensajeErrorComponent, {
          //   width: '600px',
          //   data: {
          //     'strMensaje':"La mesa esta bloqueada, por favor avise al camarero"
          //   }
          // });
          // dialogRef.afterClosed().subscribe(() => {
          //   let extra: NavigationExtras = {
          //     queryParams: {
          //       'id':this.idEstablecimiento
          //     }
          //   }
          //   this.router.navigate(['/CartaOnline'], extra)
          // });
          
        }
        this.myLoader=false;
      })
    }
  }

  modificaCantidad(l){
    // this.myLoader=true;

    let dialogRef = this.dialog.open(VentanaNumLineasComponent, {
      width: '600px',
      data: {
        linea:l,
        coo:this.midataCookie.coo,
        mesa:this.midataCookie.mes
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      // console.log(response)
      if(response!=undefined){
        if(response.miRespuesta.booOk){

          //Compruebo que exista pedido
          if(response.miListPedidos.length>0){
            this.miPedido=response.miListPedidos[0];
            this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
            this.listPedidoLineas=response.miListPedidoLinea;
            this.totalArticulos=response.miListPedidoLinea[response.miListPedidoLinea.length-1].decTotalArticulos;
          }
          else{
            let extra: NavigationExtras = {
              queryParams: {
                'id':this.idEstablecimiento
              }
            }
            this.router.navigate(['/CartaOnline'], extra)
          }
        }
        else{
          this.mensajeError(response.miRespuesta.strMensaje);
        }
        this.myLoader=false;
      }
      else{
        this.myLoader=false;
        // this.mensajeError("Parece que ha ocurrido un error, intentelo nuevamente");
      }

      
    })

    // this.miservicio.putSumaLineaPedido(this.midataCookie.coo,l,this.midataCookie.mes).subscribe(datos=>{
    //   // console.log(datos)
    //   if(datos.miRespuesta.booOk){
    //     this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
    //     this.listPedidoLineas=datos.miListPedidoLinea;
    //     this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
    //   }
    //   this.myLoader=false;
    // })
  }

  // menos(l){
  //   //console.log(l);
  //   this.myLoader=true;
  //   if(l.decCantidad===1){
  //     this.mensajeError('La cantidad no puede ser menor');
  //     this.myLoader=false;
  //   }else{
  //     this.miservicio.putRestaLineaPedido(this.midataCookie.coo,l,this.midataCookie.mes).subscribe(datos=>{
  //       // console.log(datos)
  //       if(datos.miRespuesta.booOk){
  //         this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
  //         this.listPedidoLineas=datos.miListPedidoLinea;
  //         this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
  //       }
  //       this.myLoader=false;
  //     })
  //   }    
  // }

  marcarItem(event: any,l)
  {
    if(event.checked){
      this.listLineasEliminar.push(l);
    }else{
      var index = this.listLineasEliminar.map(x => {
        return x.intId;
      }).indexOf(l.intId);
      this.listLineasEliminar.splice(index, 1);
    }
  }

  eliminar(){
    this.myLoader=true;
    if(this.listLineasEliminar.length>0){
      this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
      this.miservicio.deleteLineasPedido(this.midataCookie.coo,this.listLineasEliminar,this.midataCookie.mes).subscribe(datos=>{
        // console.log(datos)
        this.myLoader=false;
        if(datos.miListPedidoLinea.length>0){
        //   // this.pedidoLineas=false;
        //   // this.pedidoVacio=true;
        //   // sessionStorage.removeItem(this.intIdPedido)
          this.listPedidoLineas=datos.miListPedidoLinea;
          this.miPedido=datos.miListPedidos[0];
          this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
        }else{
          let extra: NavigationExtras = {
            queryParams: {
              'id':this.idEstablecimiento
            }
          }
          this.router.navigate(['/CartaOnline'], extra)
        }
  
        // this.muestraSpinner=true;
        // this.muestraTotalArticulos=false;
        // this.recuperoTotalArticulos();
      })
    }else{
      let dialogRef = this.dialog.open(MensajeErrorComponent, {
        width: '600px',
        data: {
          'strMensaje':'Antes debes seleccionar alguna linea'
        }
      });
      dialogRef.afterClosed().subscribe(() => {
      });
      this.myLoader=false;
    }  
    this.listLineasEliminar.splice(0, this.listLineasEliminar.length);  
  }

  finalizaPedido(){
    // this.mensajeError("Su pedido se ha registrado correctamente, en breve le atenderemos. Muchas gracias.");

    this.myLoader=true;
    this.miservicio.finalizaPedido(this.midataCookie.coo,this.miPedido,this.midataCookie.mes).subscribe((datos:any)=>{
      this.myLoader=false;
      if(datos.booOk===true){
        // sessionStorage.removeItem(this.intIdPedido)
        this.mensajeError("Su pedido se ha registrado correctamente, en breve le atenderemos. Gracias");

        let extra: NavigationExtras = {
          queryParams: {
            'id':this.idEstablecimiento
          }
        }
        this.router.navigate(['/CartaOnline'], extra)
      } 
    })
  }

  verDestacados(){
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEstablecimiento
      }
    }
    this.router.navigate(['/CartaOnline'], extra)
  }

  datosEstablecimiento(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        // console.log("est" + new Date().toLocaleString())
        this.miEstablecimiento=datos.miListEstablecimiento[0];
      }
      
      // this.colorCuerpo="#"+this.miEstablecimiento.strColorCuerpo;
      // this.colorTextoCuerpo="#"+this.miEstablecimiento.strColorTextoCuerpo;
      // this.colorMenu="#"+this.miEstablecimiento.strColorMenu;
      // this.colorTextoMenu="#"+this.miEstablecimiento.strColorTextoMenu;
    })
  }

  // listArticulosDestacados(){
  //   this.listArticulos2 =this.miservicio.getArticulos(this.midataCookie.coo,this.midataCookie.mes,0,"S",0);
  // }

  // getZona(){
  //   // this.miservicio.getZona(this.midataCookie.coo,this.midataCookie.mes).subscribe(datos=>{
  //   //   this.listZona=datos;
  //   // })
  //   this.strMesa=sessionStorage.getItem(this.strNombreMesa);
  //   this.strZona=sessionStorage.getItem(this.strNombreZona);
  // }

  openNav() {
    document.getElementById("miSidenav").style.width="250px";
  
    this.abierto=true;
    this.cerrado=false;
  }

   closeNav() {
    document.getElementById("miSidenav").style.width="0";
    
    this.abierto=false;
    this.cerrado=true;    
  }

  addArticulos(){
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEstablecimiento
      }
    }
    this.router.navigate(['/CartaOnline'], extra);
  }

  private mensajeError(data:any){
    let dialogRef = this.dialog.open(MensajeErrorComponent, {
      width: '600px',
      data: {
        'strMensaje':data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      //this.listar();
    });
   }
}
