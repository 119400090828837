// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl : 'http://192.168.1.135/resttpvwa'
  baseUrl : 'https://webservice.infitec.es/resttpvwa',
  // baseUrl : 'http://localhost:35113',

  //Constantes
  intCamareroId:"1",

  //Variables cookies
  intIdPedido:"intPedidoIdCartaOnline",
  strCookiePedido:"CooPedidoOnline",
  strCookie:"CookieCartaOnline",
  strCookieNavegador:"CookieCartaOnlineNavegador",
  // intIdMesa:"intMesaIdCartaOnline",
  // strNombreMesa:"strMesaCartaOnline",
  // strNombreZona:"strZonaCartaOnline",
  // strColorMenu:"strColorMenuCartaOnline",
  // strColorTextoMenu:"strColorTextoMenuCartaOnline",
  // strColorCuerpo:"strColorCuerpoCartaOnline",
  // strColorTextoCuerpo:"strColorTextoCuerpoCartaOnline"
  strCambioClavePedido:"CAMBIO_PASS_MESA"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
