import { Component, OnInit, HostBinding, Input, HostListener } from '@angular/core';
import { trigger, animate, style, state, transition } from '@angular/animations';
import { Grupo } from 'src/app/models/grupo';
import { Articulo } from 'src/app/models/articulo';
import { Establecimiento } from 'src/app/models/establecimiento';
import { Zona } from 'src/app/models/zona';
import { environment } from 'src/environments/environment';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DetalleComponent } from '../detalle/detalle.component';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { Mesa } from 'src/app/models/mesa';
import { VentanaEmergenteComponent } from 'src/app/ventana-emergente/ventana-emergente.component';
import { NombreClienteComponent } from '../nombre-cliente/nombre-cliente.component';
import { DataCookie } from 'src/app/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';
import { DataCookiePedido } from 'src/app/models/data-cookie-pedido';
import { VentanaNumArticulosComponent } from '../ventana-num-articulos/ventana-num-articulos.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidenavComponent implements OnInit {

  abierto:boolean=true;
  cerrado:boolean=false;

  grupoId:any;
  listaGrupos:Grupo[]=[];

  listArticulos2:Articulo[]=[];
  // listaTemporal:any;
  miEstablecimiento:Establecimiento= new Establecimiento();
  // listZona:Zona[]=[];
  miZona:Zona= new Zona();

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  miGrupo:Grupo=new Grupo();

  nombreGrupo:string;
  sinDatos:boolean=false;
  myLoader:boolean=false;

  muestraTotalArticulos:boolean=false;
  totalArticulos:number;

  /**********************/
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() depth: number;

  cookieNombre:string=environment.strCookie;
  midataCookie:DataCookie=new DataCookie();
  aceptCookies=false;
  cookieNombreNavegador=environment.strCookieNavegador;
  // MesaId:string=environment.intIdMesa;
  intIdPedido:string=environment.intIdPedido;
  cookiePedido=environment.strCookiePedido;
  midataCookiePedido:DataCookiePedido=new DataCookiePedido();

  // strColorMenu:string=environment.strColorMenu;
  // strColorTextoMenu:string=environment.strColorTextoMenu;
  // strColorCuerpo:string=environment.strColorCuerpo;
  // strColorTextoCuerpo:string=environment.strColorTextoCuerpo;
  // strNombreZona:string=environment.strNombreZona;
  // strNombreMesa:string=environment.strNombreMesa;

  strMesa:string;
  strZona:string;

  miMesa:Mesa=new Mesa();
  strClave:string;
  idEstablecimiento:string="";

  showBtnComprar=false;
  IsCookiePedidoExists:boolean;

  constructor(private miservicio:CartaOnlineService,private router: Router,private route: ActivatedRoute,public dialog: MatDialog,private cookieService: CookieService) { 
    if (this.depth === undefined) {
      this.depth = 0;
    }

    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
    });
  }

  ngOnInit() {
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.idEstablecimiento);
    if(IsCookieExists){
      this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento))
      // console.log(this.midataCookie)
      // this.cargarVariablesCookies();
      this.ComprobarCookiesNavegador();
      this.IsCookiePedidoExists=this.cookieService.check(this.cookiePedido+this.idEstablecimiento);
      if(this.IsCookiePedidoExists){
        this.midataCookiePedido=JSON.parse(this.cookieService.get(this.cookiePedido+this.idEstablecimiento));
        // console.log(this.midataCookiePedido)
      }
      setTimeout(()=>{
        this.colorCuerpo=this.midataCookie.strColorCuerpo;
        this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
        this.colorMenu=this.midataCookie.strColorMenu;
        this.colorTextoMenu=this.midataCookie.strColorTextoMenu;
        this.strMesa=this.midataCookie.nombreMesa;
        this.strZona=this.midataCookie.nombreZona;
        // this.recuperaMesa();
        this.recuperaTodosDatos();
      }, 500)
    }else{
      this.mensajeError("La cookie ha caducado, por favor escanee nuevamente el codigo QR");
    }
    
    this.myLoader=true;
    // this.datosEstablecimiento();
    // this.listGrupos();
    // this.listArticulosDestacados();    
    // this.getZona();
    if(this.nombreGrupo===undefined){
      this.nombreGrupo="Destacados";
    }
   
    // this.recuperoTotalArticulos();
    // this.recuperaMesa();
  }

  removeCookiePedido(){
    this.cookieService.delete(this.cookiePedido+this.idEstablecimiento,'',window.location.hostname);
  }
  // @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event) {
  //   sessionStorage.clear();
  // }

  recuperaMesa(){
    this.miservicio.getMesas(this.midataCookie.coo,this.midataCookie.mes).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miMesa=datos.miListMesa[0];
        this.datosEstablecimiento();
      }else{
        this.mensajeError(datos.miRespuesta.strMensaje);
      }
    })
  }

  recuperaTodosDatos(){

    if(this.IsCookiePedidoExists){
      this.miservicio.getLineasPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
        // console.log(datos.length)
        // this.myLoader=false;
        if(datos.length>0){
          this.totalArticulos=datos[datos.length-1].decTotalArticulos;
          this.muestraTotalArticulos=true;
        }
      })
    }

    //unico rest para recuperar los datos de mesa, establecimiento, grupos, articulos
    // console.log(77)
    this.miservicio.getDatosVarios(this.midataCookie.coo,this.midataCookie.mes).subscribe(datos=>{
      // console.log(datos)

      if(datos.miRespuesta.booOk){
        this.miMesa=datos.miListMesa[0];
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        this.listaGrupos=datos.miListGrupo;
        
        this.myLoader=false;

        //Si no tiene articulos destacados muestro mensaje
        if(datos.miListArticulo.length>0){
          this.listArticulos2=datos.miListArticulo;
        }
        else{
          this.sinDatos=true;
        }

        // this.sinDatos=false;
      }else{
        this.myLoader=false;
        // this.sinDatos=true;
        this.mensajeError(datos.miRespuesta.strMensaje);
      }
    })

    //Despues compruebo el pedido
    
    // this.recuperoTotalArticulos();
  }

  //PARA COOKIES DEL NAVEGADOR
  ComprobarCookiesNavegador(){
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombreNavegador+this.idEstablecimiento);
    if(IsCookieExists){
      this.aceptCookies=false;
    }else{
      this.aceptCookies=true;
    }
  }

  cargarVariablesCookies(){
    // this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
    // this.colorMenu=sessionStorage.getItem(this.strColorMenu);
    // this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);
    // this.colorTextoMenu=sessionStorage.getItem(this.strColorTextoMenu);
  }

  onItemSelected(item: Grupo) {
    // console.log(item)
  }

  clickChield(e){
    // console.log(e)
  }

  recuperoTotalArticulos(){
    // this.listGrupos();
    
    if(this.IsCookiePedidoExists){
      this.miservicio.getLineasPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
        // console.log(datos.length)
        
        if(datos.length>0){
          this.totalArticulos=datos[datos.length-1].decTotalArticulos;
          this.muestraTotalArticulos=true;
        }
      })
    }
    this.myLoader=false;
  }

  mas(a){
    // console.log(this.miMesa)
    // this.myLoader=true;
    if(this.miMesa.booMesaBloqueada){
      this.myLoader=false;
      let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
        width: '600px',
        data:{
          texto:"La mesa esta bloqueada, por favor avise al camarero",
          boton:false,
          btnError:true
        }
      });
    }else{
      //Si no existe la clave, se la pido al cliente


      if(this.midataCookiePedido.strClavePedido!=undefined && this.midataCookiePedido.strClavePedido!=""){
        // console.log(this.addArticuloPedido(a));
        let dialogRef = this.dialog.open(VentanaNumArticulosComponent, {
          width: '600px',
          data: a
        });
        dialogRef.afterClosed().subscribe(response => {
          if(response!=undefined){
            this.myLoader=true;
            this.miservicio.putPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes,a,"",response).subscribe((datos:any)=>{
              // console.log(datos)
              if(datos.miRespuesta.booOk){
                if(!this.IsCookiePedidoExists){
                  this.midataCookiePedido.intPedidoId=datos.miListPedidos[0].intId;
                  this.midataCookiePedido.strClavePedido=datos.miListPedidos[0].strPedidoClave;
                  this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);
                }
                this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
                this.muestraTotalArticulos=true;
                this.myLoader=false;
                // this.recuperoTotalArticulos();          
              }else{
                this.mensajeError(datos.miRespuesta.strMensaje);
                this.myLoader=false;

                if(datos.miRespuesta.strValor===environment.strCambioClavePedido){
                  // console.log(77)
                  //Elimino la cookie y vuelvo a pedir la clave
                  this.removeCookiePedido()
                  window.location.reload();
                }
              }
            })
          }
        });
       
      }else{
        //Compruebo si existe pedido en curso para esa mesa
        let dialogRef = this.dialog.open(NombreClienteComponent, {
          width: '400px'      
        });
        dialogRef.afterClosed().subscribe(nombre => {
          if(nombre!=undefined){
            //Compruebo cabecera del pedido, si no existe la creo para guardar la contraseña. Si existe verifico y actualizo contraseña
            this.miservicio.verificaPassPedido(this.midataCookie.coo,this.midataCookie.mes,nombre).subscribe(data=>{
              // console.log(data)

              if(data.miRespuesta.booOk){
                //Si existiese una cookie anterior la elimino
                this.removeCookiePedido();

                //Guardo la cookie
                this.midataCookiePedido.intPedidoId=data.miListPedidos[0].intId;
                this.midataCookiePedido.strClavePedido=data.miListPedidos[0].strPedidoClave;
                this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);

                if(data.miListPedidoLinea.length>0){
                  this.totalArticulos=data.miListPedidoLinea[data.miListPedidoLinea.length-1].decTotalArticulos;
                  this.muestraTotalArticulos=true;
                }

                //Pido la cantidad para el pedido
                let dialogRef = this.dialog.open(VentanaNumArticulosComponent, {
                  width: '600px',
                  data: a
                });
                dialogRef.afterClosed().subscribe(response => {
                  if(response!=undefined)
                  {
                    this.miservicio.putPedido(this.midataCookie.coo,'',this.midataCookie.mes,a,nombre,response).subscribe(datos=>{  
                      // console.log(datos)
                      // this.myLoader=true;
                      if(datos.miRespuesta.booOk){
                        // this.removeCookiePedido();
                        // this.midataCookiePedido.intPedidoId=datos.miListPedidos[0].intId;
                        // this.midataCookiePedido.strClavePedido=datos.miListPedidos[0].strPedidoClave;
                        // this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);
      
                        this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
                        this.muestraTotalArticulos=true;
                        this.myLoader=false;
                      }else{
                        this.myLoader=false;
                        this.mensajeError(datos.miRespuesta.strMensaje);
                      }
                    })
                  }
                })
              }
              else{
                this.mensajeError(data.miRespuesta.strMensaje);
              }
            })
          }
        });
      }
    }
  }

  // addArticuloPedido(articulo){
  //   let dialogRef = this.dialog.open(VentanaNumArticulosComponent, {
  //     width: '600px',
  //     data: articulo
  //   });
  //   dialogRef.afterClosed().subscribe(data => {
  //     // console.log(data)
  //     return data;
  //   });
  // }

  resumen(){
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEstablecimiento
      }
    }
    this.router.navigate(['resumen'], extra);
  }

  listGrupos(){
    this.listaGrupos.splice(0, this.listaGrupos.length);
    this.miservicio.getGrupos(this.midataCookie.coo).subscribe(datos=>{
      this.listaGrupos=datos;
      this.listArticulosDestacados(); 
    })
  }

  datosEstablecimiento(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        this.recuperoTotalArticulos();
      }
      
      
      // this.colorCuerpo="#"+this.miEstablecimiento.strColorCuerpo;
      // this.colorTextoCuerpo="#"+this.miEstablecimiento.strColorTextoCuerpo;
      // this.colorMenu="#"+this.miEstablecimiento.strColorMenu;
      // this.colorTextoMenu="#"+this.miEstablecimiento.strColorTextoMenu;
    })
  }

  listarArticulos(){
    this.listArticulos2.splice(0, this.listArticulos2.length);
    this.miservicio.getArticulos(this.midataCookie.coo,this.midataCookie.mes,0,"",this.grupoId).subscribe(datos=>{
      // console.log(datos)
      // this.listArticulos2.splice(0, this.listArticulos2.length);

      if(datos.miRespuesta.booOk){
        this.listArticulos2=datos.miListArticulo;
        this.myLoader=false;
        this.sinDatos=false;
      }else{
        this.sinDatos=true;
        this.myLoader=false;
      }
    })
  }

  verDestacados(){
    this.nombreGrupo="Destacados";
    this.myLoader=true;
    this.listArticulosDestacados();
  }

  listArticulosDestacados(){
    this.listArticulos2.splice(0, this.listArticulos2.length);
    this.miservicio.getArticulos(this.midataCookie.coo,this.midataCookie.mes,0,"S",0).subscribe(datos=>{
      // console.log(datos)
      // this.listArticulos2.splice(0, this.listArticulos2.length);

      if(datos.miRespuesta.booOk){
        this.listArticulos2=datos.miListArticulo;
        this.myLoader=false;
        this.sinDatos=false;
      }else{
        this.sinDatos=true;
        this.myLoader=false;
      }
      
    })
  }

//   fetchEvent(){
//     return  this.miservicio.getArticulos2(this.midataCookie.coo,this.midataCookie.mes,0,"",this.grupoId).then(event => {
//         this.listaTemporal = event;
//     });
//  }

  getGrupo(grupo:Grupo){
    this.myLoader=true;

    this.nombreGrupo=grupo.strNombre;
    this.grupoId=grupo.intId;
    
    document.getElementById("cierraMenu").click();   

    this.listarArticulos();
  }

  getDestacado(){
    this.myLoader=true;
    this.nombreGrupo="Destacados";
    this.listArticulosDestacados();
    document.getElementById("cierraMenu").click();
  }

  getZona(){
    // this.miservicio.getZona(this.midataCookie.coo,this.midataCookie.mes).subscribe(datos=>{
    //   this.miZona=datos[0];
    // })

    // console.log(sessionStorage.getItem(this.strNombreMesa))

    // this.strMesa=sessionStorage.getItem(this.strNombreMesa);
    // this.strZona=sessionStorage.getItem(this.strNombreZona);
  }

  verArticulo(a:Articulo){
    let dialogRef = this.dialog.open(DetalleComponent, {
      width: '600px',
      data: {
        'intId':a.intId,
        'coo':this.midataCookie.coo,
        'mes':this.midataCookie.mes,
        'est':this.midataCookie.est
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }


  openNav() {
    document.getElementById("miSidenav").style.width="250px";
  
    this.abierto=true;
    this.cerrado=false;
  }
   closeNav() {
    document.getElementById("miSidenav").style.width="0";
    
    this.abierto=false;
    this.cerrado=true;
    
  }

  private mensajeError(data:any){
    let dialogRef = this.dialog.open(MensajeErrorComponent, {
      width: '600px',
      data: {
        'strMensaje':data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
   }
}